import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome to johnzeller.com!</h1>
        <p>
          I'll be working on the new design for this site for a bit.
          In the meantime, check out my{" "}
          <a target="_blank" href="https://www.linkedin.com/in/johnlzeller/">LinkedIn</a>
        </p>
      </header>
    </div>
  );
}

export default App;
